import Alpine from 'alpinejs'
window.Alpine = Alpine

// Move these code to resources/js/bootstrap
import contractTimelineDetail from "./contracts/timeline-detail";
Alpine.data('contractTimelineDetail', contractTimelineDetail)

console.log('start alpine Js')

Alpine.start()
