export default (url = null, payload = {}) => ({
    loading: false,
    message: null,
    haveError: false,
    showRestMessage: false,

    handle () {

        this.loading = true;

        axios.post(url, {
            amount: this.amount,
            date: this.date,
            collected_by: this.collected_by,
            group_id: this.group_id,
            timeline_id: this.timeline_id,
            comment: this.comment,
            deposited_by_customer: this.deposited_by_customer,
            payment_mode_id: this.payment_mode_id,
            // another form field

        }).then(response => {

            if (! response.data.error) {

                this.haveError = false;

                this._redirectTo()

                setTimeout(() => {
                    this.loading = false
                }, 3000)

            } else {
                this.haveError = true;
                this.message = response?.data?.message;
            }

        }).catch(error => {
            this.haveError = true;
            this.message = error.response.data.message;
            this.loading = false

        }).finally(() => {
            this.loading = false
        })

    },

    init() {
        this._hydrate();
    },

    _hydrate() {

        const data = JSON.parse(payload)

        console.log('payload', data)
        console.log('resource for : url', url)

        for (const key in data) {
            this[key] = data[key]
        }
    },

    _redirectTo() {
        return window.location.reload()
    }
})
